'use client'
import { handleImageLeave, handleImageToggle } from '~/components/Footer/handlers';
import { v4 as uuid } from 'uuid';
import useStores from '~/store/StoreHook';

export default function Chatbox({ isMobile }) {
  const { accountStore } = useStores();

  function handleOnClick(e) {
    e.preventDefault();
    let email;
    let token;
    let user;
    const userGuest =
      accountStore.person.userGuest !== undefined ? accountStore.person.userGuest : accountStore.userGuest;
    if (!userGuest) {
      email = accountStore.person.data && accountStore.person.data.data ? accountStore.person.data.data.logon_id : null;
      token =
        accountStore.person.data && accountStore.person.data.data && accountStore.person.data.data.token
          ? accountStore.person.data.data.token.id_token
          : null;
      user =
        accountStore.person.data && accountStore.person.data.data && accountStore.person.data.data.data
          ? accountStore.person.data.data.data
          : null;
    }
    //  Token: userGuest ? null : token,
    const json = {
      Token: null,
      Region: userGuest ? null : process.env.NEXT_PUBLIC_CHATBOT_REGION,
      Category: userGuest ? 999 : parseInt(process.env.NEXT_PUBLIC_CHATBOT_CATEGORY, 10),
      Id: uuid(),
      Date: new Date().toISOString(),
      Origin: userGuest ? 7 : 13,
      CustomerName: userGuest
        ? null
        : user && (user.first_name || user.last_name)
        ? `${user.first_name} ${user.last_name}`
        : null,
      AccountNumber: userGuest ? null : user && user.phone_1 ? user.phone_1 : null,
      Email: userGuest ? null : email,
      AppType: 'Web',
      Cedula: null,
    };

    const id = btoa(JSON.stringify(json));
    const endpoint = `${process.env.NEXT_PUBLIC_CHATBOT}/Chat/Chat?id=${id}`;
    window.open(endpoint, 'chat', 'width=450, height=670, left=20, top=100');
  }

  return (
    <a href="#" onClick={handleOnClick}>
      {isMobile ? (
        <picture className="footer--chatbox-mobile">
          <img className="chatbox-mobile" src="/cdn/espots/media/chatbox_mobile.png" alt="image_chatbox_responsive" />
        </picture>
      ) : (
        <picture className="footer--chatbox-pc">
          <img
            src="/cdn/espots/media/icon-chat-inicio.gif"
            alt=""
            onMouseEnter={handleImageToggle}
            onMouseLeave={handleImageLeave}
          />
        </picture>
      )}
    </a>
  );
}
